import React from "react";
import "styles/global.css";
import { Col, Button } from "react-bootstrap";
import moment from "moment";

const InfoContainer = ({ isOpen, victimData, setHidden, hidden }) => {
  return (
    <div
      className={
        isOpen
          ? "info-container text-center open"
          : "info-container text-center close"
      }
    >
      <div className="info-container-ime">
        <h4 style={{ textTransform: "capitalize" }}>
          {victimData?.ime} ({victimData?.ime_oca}) {victimData?.prezime}
        </h4>
        <p className="info-container-date">
          {moment(victimData?.datum_rodjenja).format("DD.MM.YYYY.")}
        </p>
      </div>
      <div className="info-container-content">
        <div className="info-container-infos">
          <Col md={3} sm={3} xs={4}>
            <p className="info-container-heads">Parcela</p>
            <p className="info-container-info">{victimData?.parcela}</p>
          </Col>
          <Col md={3} sm={3} xs={4}>
            <p className="info-container-heads">Red</p>
            <p className="info-container-info">{victimData?.red}</p>
          </Col>
          <Col md={3} sm={3} xs={4}>
            <p className="info-container-heads">Grobno mjesto</p>
            <p className="info-container-info">{victimData?.grobno_mjesto}</p>
          </Col>
        </div>
        <div className="info-container-button">
          <Button onClick={() => setHidden(!hidden)}>Vidi galeriju</Button>
        </div>
      </div>
    </div>
  );
};

export default InfoContainer;
