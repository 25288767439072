import React from 'react'
import { Row, Col } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'
import mmsLogo from 'assets/newLogo.png'
import arrowLeft from 'assets/arrow-left.svg'

const OCentru = () => {
    const history = useHistory()
    let from = history.location?.state?.from
    return (
        <div className='main-page-wrapper main-page'>
            <Row className='results-page-header'>
                <Col md={4} sm={4} xs={4} className='text-center'>
                    <div className='back-to-search' onClick={() => history.goBack()}>
                        <img className='back-arrow result-back'  src={arrowLeft} alt='Left arrow'/>
                        <span className='back-text'>Nazad na {from === 'Map' ? "mapu" : "pretragu"}</span>
                    </div>
                </Col>
                <Col md={4} sm={4} xs={4}></Col>
                <Col md={4} sm={4} xs={4} className='text-center'>
                    <img alt='Memorial Center Srebrenica' src={mmsLogo} onClick={() => history.goBack()} className='mms-image result-mms mms-result'/>
                </Col>
            </Row>
            <Row className='text-center'>
                <Col md={12} lg={12} xs={12}>
                    <h3 className='oNama'>O nama</h3>
                </Col>
            </Row>
            <Row className='text-row'>
                <Col md={12}>
                    <p className='paragraph'>
                        Odlukom Visokog predstavnika za Bosnu i Hercegovinu 2000. godine osnovan je Memorijalni centar Srebrenica-Potočari Spomen obilježje i mezarje za žrtve genocida iz 1995. godine. Memorijalni centar je mjesto sjećanja na žrtve genocida u Srebrenici iz 1995. godine i posvećen je 
                        očuvanju historije i suočavanju sa silama neznanja i mržnje koje uzrokuju genocid.
                    </p>
                    <p className='paragraph'>
                    Sarađujemo sa umjetnicima i umjetnicama, te razvijamo nove izložbe u Memorijalnom centru, ali i izvan njega. Otvaramo se prema zajednici i na različite načine koristimo umjetnost u prenošenju poruke i informacija. Učestvujemo u organizaciji Dana sjećanja na žrtve genocida – 11. jula, te podržavamo sve aktivnosti vezano za ovaj datum. Pomažemo i ohrabrujemo novinare i istraživače da posjećuju Srebrenicu, koriste naš Dokumentacioni centar, Arhiv, Biblioteku i sve dostupne podatke za realizaciju novih priča i naučnih radova 
                    koji će doprinijeti prevenciji genocida u svijetu.
                    </p>
                    <p className='paragraph'>
                    Negiranje genocida i islamofobija ostaju ozbiljne prepreke miru i pomirenju u 
                    Bosni i Hercegovini. U Memorijalnom centru razumijemo opasnost koju historijski revizionizam predstavlja za procese izgradnje mira i tranzicijske pravde. Kroz pristup zasnovan na činjenicama koji promovira toleranciju, međuetnički dijalog i pomirenje posvećeni smo suočavanju sa pogubnim silama koje nastoje negirati bosansko historijsko iskustvo.
                    </p>
                </Col>
            </Row>
        </div>
    )
}

export default OCentru
