import React, { useState, useEffect } from "react";
import MobileDetect from "mobile-detect";
import { Row, Col, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useHistory } from "react-router";
import styled from "styled-components";
import mmsImage from "assets/newLogo.png";
import infoImg from "assets/info.svg";
import logo from "assets/modicon.png";
import { FcGoogle } from "react-icons/fc";
import VisiotLogo from "assets/visiot-logo-sivi.svg";

const MainPage = () => {
  const [data, setData] = useState({
    ime: "",
    prezime: "",
    imeOca: "",
  });
  let md = new MobileDetect(window.navigator.userAgent);
  const [os, setOs] = useState("");
  const handle = md.os()?.length > 0 ? false : true;
  const [hidden, setHidden] = useState(handle);
  const token = window.localStorage.token;
  const history = useHistory();
  let height = window.screen.height;
  const goToResults = () => {
    const { ime, prezime, imeOca } = data;
    history.push(`rezultati?ime=${ime}&prezime=${prezime}&imeOca=${imeOca}`);
  };
  console.log(os);
  useEffect(() => {
    setOs(md.os());
    console.log(md.os());
  });
  return (
    <>
      {token ? (
        history.push("/table")
      ) : (
        <div className="main-page-wrapper main-page">
          <Row>
            <div className="head">
              <Link
                className="link"
                style={{
                  float: "left",
                  marginTop: "20px",
                  marginLeft: "15px",
                }}
                to="/login"
              >
                Login
              </Link>
              {/* <Link
                to={{
                  pathname:
                    "https://srebrenicamemorial.org/bs/stranica/o-nama/3?tab=historija-memorijalnog-centra",
                }}
                target="_blank"
              >
                <div
                  className="info-img-wrapper"
                  style={{
                    float: "right",
                    marginTop: "20px",
                    marginRight: "15px",
                  }}
                >
                  <img src={infoImg} alt="Info" className="info-image" />
                  <p className="info-text" style={{ color: "#FFF" }}>
                    O centru
                  </p>
                </div>
              </Link> */}
            </div>
          </Row>

          <Row className="headline-row">
            <Col sm={12}>
              <h2>Unesite podatke za pretragu</h2>
            </Col>
            <Col sm={12}>
              <p>
                Ispunjavanjem većeg broja polja, imate
                <br /> bolju šansu da pronađete tačno mjesto
                <br /> ukopa.
              </p>
            </Col>
          </Row>
          <Row className="form-row">
            <Form>
              <Form.Group className="mb-3">
                <Form.Control
                  type="text"
                  placeholder="Ime (opcionalno)"
                  onChange={(e) => setData({ ...data, ime: e.target.value })}
                  onKeyPress={(e) => (e.key === "Enter" ? goToResults() : null)}
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Control
                  type="text"
                  placeholder="Prezime (opcionalno)"
                  onChange={(e) =>
                    setData({ ...data, prezime: e.target.value })
                  }
                  onKeyPress={(e) => (e.key === "Enter" ? goToResults() : null)}
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Control
                  type="text"
                  placeholder="Ime oca (opcionalno)"
                  onChange={(e) => setData({ ...data, imeOca: e.target.value })}
                  onKeyPress={(e) => (e.key === "Enter" ? goToResults() : null)}
                />
              </Form.Group>
            </Form>
            <Col sm={12}>
              <button
                className={
                  data.ime === "" && data.prezime === "" && data.imeOca === ""
                    ? "disabled-button"
                    : "search-button"
                }
                disabled={
                  data.ime === "" && data.prezime === "" && data.imeOca === ""
                    ? true
                    : false
                }
                onClick={goToResults}
              >
                {"Pretraži"}
              </button>
            </Col>
          </Row>
          <Row className="footer-row">
            <Col md={4} sm={12} className="col text-center">
              <img
                src={mmsImage}
                alt="Memorial Center Srebrenica"
                className="mms-image main-mms main-mms-image"
              />
            </Col>
            <Col md={4} sm={12} className="">
              {/* <Link
                to={{ pathname: "https://visiot.net/" }}
                target="_blank"
                className="logo-container col text-center"
                style={{ textDecoration: "none" }}
              > */}
              <div className="logo-container col text-center">
                <img
                  src={VisiotLogo}
                  alt="visiot-logo"
                  className="visiot-logo-black"
                />
                <p className="dev-by">
                  Developed by
                  <br /> <span className="visiot-main">Visiot Visoko</span>
                </p>
                </div>
              {/* </Link> */}
            </Col>
            <Col md={4} sm={12} className="col text-center">
              <p className="ajet">
                "I ne recite za one koji su na Allahovu
                <br /> putu poginuli: "Mrtvi su!" Ne, oni su
                <br /> živi, ali vi to ne znate“!
              </p>
              <p className="el-bekare">El-Bekare</p>
            </Col>
            {hidden ? null : (
              <Device height={height}>
                <div className="wrapper">
                  <div className="header">
                    <span>Nastavi gledati na...</span>
                  </div>
                  <div className="cont">
                    <div>
                      <img src={logo} />
                      <h5>Mezarje Genocida App</h5>
                      <Link
                        to={{
                          pathname: /iPad|iPhone|iPod/.test(navigator.userAgent)
                            ? "#"
                            : "https://play.google.com/store/apps/details?id=com.srebrenica",
                        }}
                        target="_blank"
                        onClick={() => {
                          const isIOS = /iPad|iPhone|iPod/.test(
                            navigator.userAgent
                          );
                          if (isIOS) {
                            alert("Aplikacija za iOS uređaje je u pripremi.");
                          }
                          setHidden(!hidden);
                        }}
                      >
                        Preuzmi
                      </Link>
                    </div>
                    <div style={{ margin: "2rem 0 0 0" }}>
                      <FcGoogle size={45} />
                      <h5>Google</h5>
                      <button
                        style={{ background: "#EFEFEF", color: "#7e7e7e" }}
                        onClick={() => setHidden(!hidden)}
                      >
                        Nastavi
                      </button>
                    </div>
                  </div>
                </div>
              </Device>
            )}
          </Row>
        </div>
      )}
    </>
  );
};
export default MainPage;

const Device = styled.div`
  height: ${(props) => props.height / 2.5}px;
  background-color: white;
  position: absolute;
  bottom: 0px;
  left: 0;
  right: 0;
  margin: 0 auto;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 0px -3px 3px rgba(0, 0, 0, 0.1);
  border-radius: 10px 10px 0px 0px;

  .wrapper {
    padding: 1rem;
    display: flex;
    flex-direction: column;
  }
  .wrapper .header {
    font-family: Calibri;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: #000000a0;
    padding-bottom: 1rem;
    border-bottom: 1px solid rgba(0, 0, 0, 0.5);
  }
  .wrapper .cont {
    padding: 3rem 0 0 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 1rem;
  }
  .wrapper .cont div {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .cont div h5 {
    font-family: Calibri;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 22px;
    color: #000000;
    text-align: center;
  }
  .cont div button,
  .cont a {
    padding: 0.8rem 2rem;
    border: none;
    color: white;
    background: #282829;
    border-radius: 20px;
    font-size: 13px;
    font-weight: bold;
    text-decoration: none;
  }
  .wrapper .cont div img {
    width: 48px;
  }
`;
