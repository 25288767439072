import MainPage from "./containers/pages/MainPage/MainPage";
import ResultsPage from "containers/pages/ResultsPage/ResultsPage";
import MapPage from "containers/pages/MapPage/MapPage";
import OCentru from "containers/pages/OCentru/OCentru";
import Tablepage from "containers/pages/TablePage/Tablepage";
import Tablemore from "containers/pages/TableMore/Tablemore";
import Login from "containers/pages/Login/Login";
const routes = [
  { path: "/login", component: Login },
  { path: "/tablemore", component: Tablemore },
  { path: "/table", component: Tablepage },
  {
    path: "/centar",
    component: OCentru,
  },
  {
    path: "/rezultati",
    component: ResultsPage,
  },
  {
    path: "/mapa",
    component: MapPage,
  },
  {
    path: "/",
    component: MainPage,
  },
];

export default routes;
