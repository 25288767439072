import React, { useState } from "react";
import styled from "styled-components";
import apiRequest from "helpers/apiRequest";
import { useHistory } from "react-router-dom";
import Header from "components/Header/Header";
const Login = () => {
  const [data, setData] = useState({
    email: "",
    password: "",
  });
  const [token, setToken] = useState("");
  const [hidden, setHidden] = useState(true);
  let success = false;
  if (token) {
    success = true;
  } else {
    success = false;
  }
  const history = useHistory();

  const getToken = async () => {
    await apiRequest({
      method: "post",
      url: `auth/login`,
      data,
    })
      .then((resp) => {
        window.localStorage.setItem("token", resp.data.token);
        setToken(window.localStorage.getItem("token"));
        history.push("/table");
      })
      .catch((err) => {
        setHidden(false);
        if (err.message.includes("401")) {
          window.localStorage.clear();
          history.push("/login");
        }
      });
  };
  return (
    <Container>
      <Header onClick={() => history.push("/")} />
      {hidden ? (
        <div className="content">
          <h1>Prijavite se</h1>
          <div className="form">
            <input
              type="email"
              placeholder="Unesite email"
              onChange={(e) => setData({ ...data, email: e.target.value })}
            />
            <input
              type="password"
              placeholder="Unesite šifru"
              onChange={(e) => setData({ ...data, password: e.target.value })}
            />
            <button
              className={
                data.email === "" || data.password === ""
                  ? "disabled-button"
                  : "login-button"
              }
              disabled={
                data.email === "" || data.password === "" ? true : false
              }
              onClick={getToken}
            >
              Prijavi se
            </button>
          </div>
        </div>
      ) : (
        <Warning>
          <div className="wrapper">
            <div className="header">
              <h3>Pogrešan unos podataka!</h3>
            </div>
            <div className="text">
              <p>
                Došlo je do problema pri prijavi. Vaš email ili šifra nisu
                tačni. Molimo provjerite Vaše podatke za prijavu! Ukoliko ste
                zaboravili Vašu šifru, molimo Vas javite se na mail
                <span> info@visiot.net.</span>
              </p>
            </div>
            <div className="button">
              <button onClick={() => setHidden(!hidden)}>Pokušaj ponovo</button>
            </div>
          </div>
        </Warning>
      )}
    </Container>
  );
};

export default Login;

const Container = styled.div`
  height: 100vh;
  width: 100%;
  overflow-x: hidden;
  background: #000;
  background-size: cover;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  .content {
    width: 100%;
    padding-top: 10%;
    display: flex;
    flex-direction: column;
    align-items: center;
    h1 {
      color: #fff;
      font-size: 50px;
    }
    .form {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 2rem 0;
      input {
        width: 30%;
        margin-bottom: 1rem;
        height: 50px;
        border-radius: 10px;
        border: none;
        outline: none;
        padding-left: 1%;
        transition: all 200ms ease;
        &:focus {
          outline: 3px solid #002266;
        }
        @media (max-width: 425px) {
          width: 90%;
        }
      }
      button {
        height: 50px;
        width: 13%;
        border-radius: 10px;
        background-color: #363636;
        color: #fff;
        border: none;
        outline: none;
        cursor: pointer;
        font-size: 15px;
        font-weight: normal;
        letter-spacing: 0.09em;
        @media (max-width: 425px) {
          width: 90%;
        }
      }
    }
  }
`;

const Warning = styled.div`
  width: 694px;
  height: 317px;
  background: rgba(250, 250, 250);
  margin-top: 10%;
  border-radius: 20px;
  position: relative;
  .wrapper {
    padding: 3rem;
    display: flex;
    flex-direction: column;
  }
  .header {
    font-family: Calibri;
    font-size: 24px;
    line-height: 29px;
    text-align: left;
    color: #000000;
    border-bottom: 0.2px solid rgba(0, 0, 0, 0.2);
    flex: 1;
  }
  .text {
    max-width: 665px;
    width: 100%;
    padding: 1rem;
    flex: 1;
    font-family: Calibri;
    font-size: 18px;
    font-weight: 500;
    line-height: 20px;
    text-align: center;
    color: #000000;
  }
  .text span {
    color: blue;
  }
  .button {
    padding: 1rem;
    flex: 1;
    align-self: center;
  }
  .button button {
    height: 55px;
    width: 300px;
    border-radius: 20px;
    background-color: #000;
    color: #fff;
    border: none;
    outline: none;
    cursor: pointer;
    font-size: 15px;
    font-weight: normal;
    letter-spacing: 0.09em;
  }
`;
