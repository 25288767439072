import React, { useState, useEffect } from "react";
import { useTable, usePagination } from "react-table";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import apiRequest from "helpers/apiRequest";
import Header from "../../../components/Header/Header";

function Table({ columns, data, searchData, setsearchData, getData, total }) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    nextPage,
    previousPage,
    rows,
  } = useTable(
    {
      columns,
      data,
      searchData,
      setsearchData,
      getData,
      total,
      initialState: { pageIndex: 0 },
    },
    usePagination
  );
  return (
    <>
      <div className="inputs">
        <Input
          type="text"
          placeholder="Unesi ime "
          onChange={(e) =>
            setsearchData({ ...searchData, ime: e.target.value })
          }
          onKeyPress={(e) => (e.key === "Enter" ? getData() : null)}
        />
        <Input
          type="text"
          placeholder="Unesi ime oca "
          onChange={(e) =>
            setsearchData({ ...searchData, imeOca: e.target.value })
          }
          onKeyPress={(e) => (e.key === "Enter" ? getData() : null)}
        />
        <Input
          type="text"
          placeholder="Unesi prezime "
          onChange={(e) =>
            setsearchData({ ...searchData, prezime: e.target.value })
          }
          onKeyPress={(e) => (e.key === "Enter" ? getData() : null)}
        />
        <Button className="search" onClick={() => getData()}>
          Pretraži
        </Button>
      </div>
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps()}>{column.render("Header")}</th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return (
                    <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>

      <div className="pagination">
        <span>
          Prikazuje se
          <strong> {rows.length}</strong> rezultata
        </span>
        {total === 0 ? (
          <strong>Nema rezultata za unijetu pretragu.</strong>
        ) : null}
        <div className="buttons">
          <Button onClick={() => previousPage()} disabled={!canPreviousPage}>
            Prethodna stranica
          </Button>
          <Button onClick={() => nextPage()} disabled={!canNextPage}>
            Sljedeća stranica
          </Button>
        </div>
      </div>
    </>
  );
}

const TablePage = () => {
  const [tableData, setTableData] = useState([]);
  const [searchData, setsearchData] = useState({
    ime: "",
    prezime: "",
    imeOca: "",
  });
  const [clicked, setClicked] = useState(false);
  const [dataId, setdataId] = useState("");
  const [total, setTotal] = useState(0);
  const history = useHistory();

  const getData = async () => {
    const { ime, prezime, imeOca } = searchData;
    try {
      const response = await apiRequest({
        method: "get",
        url: `spisak-srebrenica?prezime=${prezime}&ime=${ime}&imeOca=${imeOca}`,
      });
      if (response.status === 200) {
        setTableData(response.data);
        setTotal(response.data.length);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const columns = [
    {
      Header: "ID ŽRTVE",
      accessor: "id_grobnog_mjesta",
    },
    {
      Header: "IME (OCA) PREZIME",
      accessor: "ime_prezime",
    },
    {
      Header: "GODINA ROĐENJA",
      accessor: "godina_rodjenja",
    },
    {
      Header: "VIŠE",
      Cell: ({ row }) => (
        <Button
          position="table"
          onClick={() =>
            history.push(`tablemore/${row.values.id_grobnog_mjesta}`) ||
            setClicked(!clicked) ||
            setdataId(row.values.id_grobnog_mjesta)
          }
        >
          Više
        </Button>
      ),
    },
  ];
  return (
    <>
      {" "}
      {localStorage.token?.length > 0 ? (
        <Container>
          <Header
            text="Odjavi se"
            onClick={() => {
              window.localStorage.clear();
              history.goBack();
            }}
            className="header"
          />
          <Styles>
            <Table
              columns={columns}
              data={tableData}
              searchData={searchData}
              setsearchData={setsearchData}
              getData={getData}
              total={total}
            />
          </Styles>
        </Container>
      ) : (
        history.push("/login")
      )}
    </>
  );
};

export default TablePage;
const Container = styled.div`
  height: 100vh;
  width: 100%;
  .header {
  }
`;
const Styles = styled.div`
  padding: 1rem;
  .inputs {
    margin: 1rem auto;
    width: 90%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media (max-width: 769px) {
      flex-direction: column;
      margin: auto;
    }
  }
  table {
    width: 90%;
    margin: auto;
    height: auto;
    border-spacing: 0;
    border: 1px solid black;
    thead {
      background: #231f20;
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
      letter-spacing: 0.05em;
      text-transform: uppercase;
      color: #ffffff;
    }
    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }
    td {
      font-size: 14px;
      line-height: 20px;
      color: #6b7280;
      :nth-child(2) {
        color: #111827;
        font-weight: bold;
      }
      :first-child {
        color: #111827;
        font-weight: bold;
        font-size: 14px;
        line-height: 20px;
      }
    }
    th,
    td {
      margin: 0;
      padding: 0.5rem;
      border-bottom: 1px solid black;

      :last-child {
        border-right: 0;
      }
    }
  }

  .pagination {
    padding: 1rem 0;
    width: 90%;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media (max-width: 769px) {
      flex-direction: column;
    }

    .buttons {
      @media (max-width: 769px) {
        display: flex;
      }

      button {
        margin-left: 1rem;
      }
    }
  }
`;
const Button = styled.button`
  padding: 9px 17px;
  background: #ffffff;
  border: 1px solid #d1d5db;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  border-radius: 6px;
  transition: all 200ms ease;

  &:active {
    box-shadow: 0 1px 0 2px #ccd2d2, 0 7px 20px 6px #d5dbdb;
  }

  @media (max-width: 769px) {
    flex-direction: column;
    margin-bottom: 0.5rem;
  }
`;
const Input = styled.input`
  width: 350px;
  height: 40px;
  padding: 9px 13px;
  border: 1px solid #000000;
  border-radius: 8px;
  @media (max-width: 769px) {
    flex-direction: column;
    margin: 0.5rem 0;
  }
  @media (max-width: 360px) {
    width: 250px;
  } ;
`;
