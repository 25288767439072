import React from 'react'

const ResultCard = ({data, onClick}) => {
    return (
        <div className='result-card-wrapper'>
            <p className='name-surname-card'>{data.ime_prezime}</p>
            <p className='dob-card'>Godina rođenja: {data.godina_rodjenja}.</p>
            <button className='open-card-button' onClick={() => onClick()}>Otvori</button>
        </div>
    )
}

export default ResultCard
