/* eslint-disable import/no-anonymous-default-export */
/* eslint-disable no-unused-vars */
const APP_API_URL = process.env.REACT_APP_APP_API_URL;
const MAPBOX_ACCESS_TOKEN = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;
const API_KEY = process.env.REACT_APP_API_KEY;

export default {
  APP_API_URL,
  MAPBOX_ACCESS_TOKEN,
  API_KEY,
};
