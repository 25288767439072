import React from "react";

import { BiImageAdd } from "react-icons/bi";
import styled from "styled-components";
const Uploadzone = ({ onFileChange }) => {
  return (
    <Upload>
      <BiImageAdd size={50} opacity={0.5} />
      <input type="file" onChange={onFileChange} multiple />
      <div className="items">
        <p>Dodajte sliku</p>
        <span className="format">PNG,JPG,GIF do 10MB</span>
        <span className="format">Mozete dodati do 3 slike</span>
      </div>
    </Upload>
  );
};

export default Uploadzone;

const Upload = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  position: relative;

  input {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
  }

  .items {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-family: Barlow;
    p {
      font-weight: 500;
      font-size: 17px;
    }
    span {
      font-size: 10px;
    }
  }
`;
