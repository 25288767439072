import React, { useState, useEffect } from "react";
import Header from "components/Header/Header";
import apiRequest from "helpers/apiRequest";
import styled from "styled-components";
import RoundSpinner from "components/Loading/RoundSpinner";
import Uploadzone from "components/UploadZone/Uploadzone";
import { AiFillDelete } from "react-icons/ai";
import { useHistory } from "react-router-dom";
const Tablemore = () => {
  const [victimData, setVictimData] = useState([]);
  const [victimImage, setVictimImage] = useState([]);
  const [clicked, setClicked] = useState(false);
  const [image, setImage] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [hidden, setHidden] = useState(true);
  const [id, setId] = useState([]);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [oversize, setOversize] = useState(false);
  const pathname = `${window.location.pathname}`;
  const token = `${window.localStorage.getItem("token")}`;
  const history = useHistory();
  const getLastItem = (thePath) =>
    thePath.substring(thePath.lastIndexOf("/") + 1);
  const Id = getLastItem(pathname);

  const onFileChange = (e) => {
    if (e.target.files) {
      const filesArray = Array.from(e.target.files).map((file) =>
        URL.createObjectURL(file)
      );
      setSelectedFiles((prevImages) => prevImages.concat(filesArray));
      Array.from(e.target.files).map((file) => URL.revokeObjectURL(file));
    }
    const copy = [...image];

    if (
      e.target.files[0]?.size > 10000000
        ? // eslint-disable-next-line no-restricted-globals
          setOversize(true)
        : copy.push(e.target.files[0])
    );
    if (
      e.target.files[1]?.size > 10000000
        ? // eslint-disable-next-line no-restricted-globals
          setOversize(true)
        : copy.push(e.target.files[1])
    );
    if (
      e.target.files[2]?.size > 10000000
        ? // eslint-disable-next-line no-restricted-globals
          setOversize(true)
        : copy.push(e.target.files[2])
    );

    setImage([...copy]);
  };

  const renderPhotos = (source) => {
    return source.slice(0, 3).map((photo, index) => {
      return (
        <div key={index}>
          <Img key={index} src={photo} alt="" />
        </div>
      );
    });
  };
  const onFileUpload = () => {
    const formdata = new FormData();
    image.forEach((elem) => {
      formdata.append("data", elem);
    });
    formdata.append("id_grobnog_mjesta", Id);
    addImage(formdata);
  };

  const addImage = async (data) => {
    try {
      setIsLoading(true);
      const response = await apiRequest({
        method: "post",
        url: `spisak-srebrenica/upload`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data,
      });
      if (response.data.success) {
        getVictimImage();
      }
      setIsLoading(false);
      setImage([]);
    } catch (err) {
      setIsLoading(false);
      setImage([]);
      window.localStorage.clear();
      // eslint-disable-next-line no-restricted-globals
      confirm("Niste autorizovani. Molimo prijavite se ponovo.");
      history.push("/login");
    }
  };

  const deleteImage = async (id) => {
    try {
      setIsLoading(true);
      const response = await apiRequest({
        method: "delete",
        url: `spisak-srebrenica/pictures`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: {
          pictureId: [id],
        },
      });
      if (response) {
        getVictimImage();
      }
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      if (err.message.includes("401")) {
        window.localStorage.clear();
        // eslint-disable-next-line no-restricted-globals
        confirm("Niste autorizovani. Molimo prijavite se ponovo.");
        history.push("/login");
      }
    }
  };
  const getVictimData = async () => {
    try {
      const response = await apiRequest({
        method: "get",
        url: `spisak-srebrenica/${Id}`,
      });
      if (response.status === 200) {
        setVictimData(response.data);
      }
    } catch (err) {
      console.log(err);
    }
  };
  const getVictimImage = async () => {
    try {
      const response = await apiRequest({
        method: "get",
        url: `spisak-srebrenica/pictures/${Id}`,
      });
      if (response.status === 200) {
        setVictimImage(response.data);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getVictimData();
    getVictimImage();
  }, []);
  return (
    <>
      {localStorage.token?.length > 0 ? (
        <Container>
          <Header onClick={() => history.goBack()} text="Nazad" />
          <Wrapper>
            <div className="podaci">
              <span className="ime">
                {victimData.ime} ({victimData.ime_oca}) {victimData.prezime}
              </span>
              <span className="godina">{victimData.godina_rodjenja}</span>
              <span className="mjesto">{victimData.mjesto_rodjenja}</span>
            </div>
            <div className="slike">
              <div className="button">
                <span>Galerija slika</span>
                <div className="buttons">
                  {clicked ? (
                    <Button
                      className="close"
                      onClick={() => setClicked(false) || setSelectedFiles([])}
                    >
                      Poništi
                    </Button>
                  ) : null}
                  <Button
                    onClick={() =>
                      setClicked(!clicked) ||
                      (clicked && selectedFiles.length > 0)
                        ? onFileUpload() || setSelectedFiles([])
                        : null
                    }
                  >
                    {clicked ? "Spremi promjene" : "Uredi galeriju"}
                  </Button>
                </div>
              </div>
              {isLoading ? (
                <div className="spinner">
                  <RoundSpinner />
                </div>
              ) : (
                <div className="slikeCont">
                  {(victimImage || []).map((item, index) => (
                    <ImageCard
                      key={index}
                      style={{
                        backgroundImage: item ? `url(${item.url})` : null,
                      }}
                    >
                      {clicked ? (
                        <Button
                          className="delete"
                          onClick={() => setHidden(!hidden) || setId(item.id)}
                        >
                          Obriši
                          <AiFillDelete />
                        </Button>
                      ) : null}
                    </ImageCard>
                  ))}

                  {victimImage.length >= 3 ? null : clicked ? (
                    <ImageCard className="dot">
                      <Uploadzone onFileChange={onFileChange} />
                    </ImageCard>
                  ) : null}
                </div>
              )}
              <div className="preview">{renderPhotos(selectedFiles)}</div>
              {hidden ? null : (
                <div className="sure">
                  <span>Jeste li sigurni da želite obrisati fotografiju?</span>
                  <div className="option">
                    <Button onClick={() => deleteImage(id) && setHidden(true)}>
                      Da
                    </Button>
                    <Button className="danger" onClick={() => setHidden(true)}>
                      Ne
                    </Button>
                  </div>
                </div>
              )}
              {oversize ? (
                <Warning>
                  <div className="wrapper">
                    <div className="header">
                      <h3>Prekoračenje dozvoljenje veličine slike!</h3>
                    </div>
                    <div className="text">
                      <p>
                        Došlo je do problema pri dodavanju slika. Dozvoljena
                        veličina slike je do 10MB. Molimo pokušajte ponovo.
                      </p>
                    </div>
                    <div className="button">
                      <button
                        onClick={() =>
                          setSelectedFiles([]) ||
                          setOversize(!oversize) ||
                          setImage([])
                        }
                      >
                        Pokušaj ponovo
                      </button>
                    </div>
                  </div>
                </Warning>
              ) : null}
              {victimImage.length != 0 ? null : clicked ? null : (
                <h1 className="title">
                  {isLoading
                    ? image.length === 3
                      ? " Dodavanje slike. Molimo sačekajte."
                      : "Dodavanje slika. Molimo sačekajte."
                    : "Trenutno nema dostupnih fotografija"}
                </h1>
              )}
            </div>
          </Wrapper>
        </Container>
      ) : (
        history.push("/login")
      )}
    </>
  );
};

export default Tablemore;
const Container = styled.div`
  width: 100%;
  height: 100vh;

  @media (max-width: 425px) {
    max-width: 425px;
    width: 100%;
  }
`;
const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  font-family: Barlow;
  align-items: center;

  span: {
    font-family: Barlow !important;
  }

  .podaci {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 10rem;
    @media (max-width: 425px) {
      margin-bottom: 1rem;
    }
    .ime {
      font-weight: 600;
      font-size: 40px;
      @media (max-width: 425px) {
        font-size: 30px;
      }
    }
    .godina {
      font-weight: 500;
      font-size: 30px;

      @media (max-width: 425px) {
        font-size: 25px;
      }
    }
    .mjesto {
      font-weight: 500;
    }
  }

  .slike {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 0 5rem;
    position: relative;

    .title {
      text-align: center;
    }

    img {
      width: 150px;
      height: 150px;
    }

    .preview {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-around;
      position: relative;
      .del {
        position: absolute;
        cursor: pointer;
      }
    }
    .sure {
      width: 600px;
      height: 150px;
      background: rgba(250, 250, 250);
      position: absolute;
      right: 0;
      left: 0;
      margin: auto;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      border-radius: 20px;
      border: 1px solid black;
      @media (max-width: 425px) {
        max-width: 425px;
        width: 90%;
      }
      span {
        font-family: Barlow;
        font-weight: 500;
        color: black;
      }
      .option {
        margin: 1rem;
        .danger {
          background: #6a040f;
          color: white;
          font-weight: normal;
        }
        button {
          background: white;
          border-radius: 20px;
          color: #363636;
          font-weight: 500;
          margin: 0 1rem;
          padding: 0.1rem 2rem;
          border: 1px solid black;
        }
      }
    }

    .button {
      display: flex;
      flex-direction: column;
      align-items: center;

      span {
        font-weight: 500;
        font-size: 25px;
        margin-bottom: 15px;
        @media (max-width: 425px) {
          margin-bottom: 0;
        }
      }
      .buttons {
        @media (max-width: 425px) {
          display: flex;
          flex-direction: column;
        }
        .close {
          font-family: Barlow;
          margin-right: 1rem;
          background-color: #c4c4c4;
          color: #363636;
          font-weight: 500;

          @media (max-width: 425px) {
            margin-right: 0;
            margin-bottom: 1rem;
          }
        }
      }
    }
    .spinner {
      padding: 5rem 0;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .slikeCont {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 5rem 0;
      @media (max-width: 425px) {
        display: flex;
        flex-direction: column;
      }
    }
  }
`;
const Button = styled.button`
  background: #363636;
  border-radius: 20px;
  color: #e5e5e5;
  padding: 1rem 4rem;
  border: none;

  @media (max-width: 425px) {
    padding: 0.7rem 1rem;
  }
`;
const ImageCard = styled.div`
  width: 260px;
  height: 260px;
  background: #c4c4c4;
  background-position: center;
  background-size: cover;
  border-radius: 20px;
  margin: 0 1rem;
  border: 1px solid black;
  position: relative;
  overflow: hidden;
  @media (max-width: 425px) {
    width: 250px;
    height: 150px;
    margin: 1rem 0;
  }
  &.dot {
    border: 1px dashed black;

    img {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
    }
  }
  .delete {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(250, 250, 250, 0.8);
    border-bottom: 1px solid black;
    font-weight: 600;
    color: #6a040f;
  }
`;

const Img = styled.img`
  border: 1px solid black;
  height: 300px;
  width: 300px;
`;

const Warning = styled.div`
  width: 694px;
  height: 317px;
  background: rgba(250, 250, 250);
  border-radius: 20px;
  position: absolute;
  right: 0;
  left: 0;
  margin: auto;
  @media (max-width: 425px) {
    max-width: 425px;
    width: 100%;
  }
  .wrapper {
    padding: 3rem;
    display: flex;
    flex-direction: column;
  }
  .header {
    font-family: Calibri;
    font-size: 24px;
    line-height: 29px;
    text-align: left;
    color: #000000;
    border-bottom: 0.2px solid rgba(0, 0, 0, 0.2);
    flex: 1;
  }
  .text {
    max-width: 665px;
    width: 100%;
    padding: 1rem;
    flex: 1;
    font-family: Calibri;
    font-size: 18px;
    font-weight: 500;
    line-height: 20px;
    text-align: center;
    color: #000000;
  }
  .text span {
    color: blue;
  }
  .button {
    padding: 1rem;
    flex: 1;
    align-self: center;
  }
  .button button {
    height: 55px;
    width: 300px;
    border-radius: 20px;
    background-color: #000;
    color: #fff;
    border: none;
    outline: none;
    cursor: pointer;
    font-size: 15px;
    font-weight: normal;
    letter-spacing: 0.09em;
  }
`;
