import React from "react";
import styled from "styled-components";
import mmsLogo from "assets/newLogo.png";
import { ReactComponent as arrowLeft } from "assets/arrow-left.svg";
import { ReactComponent as info } from "assets/info.svg";
import { Link } from "react-router-dom";

const Container = styled.div`
  width: 100%;

  @media (max-width: 600px) {
    max-width: 600px;
    overflow-x: hidden;
  } ;
`;

const Topbar = styled.div`
  width: 100%;
  height: 5rem;
  background-color: #000;
`;
const TopbarWrapper = styled.div`
  height: 100%;
  width: 100%;
  padding: 0 2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media (max-width: 600px) {
    padding: 0 1rem;
  } ;
`;
const TopLeft = styled.div`
  display: flex;
  align-items: center;
`;
const Arrow = styled(arrowLeft)`
  cursor: pointer;
  @media (max-width: 600px) {
    width: 0.5rem;
  } ;
`;
const TopTitle = styled.span`
  color: #fff;
  margin-left: 1rem;
  font-size: 20px;
  cursor: pointer;
  text-decoration: none;
  &:hover {
    color: #fff;
  }
  @media (max-width: 600px) {
    margin: 0.2rem;
    font-size: 10px;
  }
`;
const Logo = styled.img`
  height: 2.5rem;
  cursor: pointer;
  @media (max-width: 600px) {
    height: 2rem;
  } ;
`;
const Info = styled(info)`
  cursor: pointer;
  @media (max-width: 600px) {
    width: 0.8rem;
  } ;
`;

const TopMid = styled.div``;
const TopRight = styled.div`
  display: flex;
  align-items: center;
`;

const Header = ({ text, onClick }) => {
  return (
    <Container>
      <Topbar>
        <TopbarWrapper>
          <TopLeft onClick={onClick}>
            <Arrow />
            <TopTitle>{text}</TopTitle>
          </TopLeft>
          <TopMid>
            <Link to={"/"}>
              <Logo src={mmsLogo} />
            </Link>
          </TopMid>
          {window.localStorage.token?.length < 0 ? (
            <TopRight>
              <Info />
              <TopTitle
                to={{
                  pathname:
                    "https://srebrenicamemorial.org/bs/stranica/o-nama/3?tab=historija-memorijalnog-centra",
                }}
                target="_blank"
              >
                O centru
              </TopTitle>
            </TopRight>
          ) : null}
        </TopbarWrapper>
      </Topbar>
    </Container>
  );
};

export default Header;
