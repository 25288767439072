import React, { useEffect, useState } from "react";
import "styles/global.css";
import styled from "styled-components";
import Map from "components/Map/Map";
import ThreeDots from "components/Loading/ThreeDots";
import InfoContainer from "components/InfoContainer/InfoContainer";
import MapHeader from "components/MapHeader/MapHeader";
import apiRequest from "helpers/apiRequest";
import queryString from "query-string";
import { AiOutlineClose } from "react-icons/ai";
import { MdArrowForwardIos, MdArrowBackIos } from "react-icons/md";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";

const MapPage = () => {
  const [lat, setLat] = useState();
  const [long, setLong] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [isContainerOpen, setIsContainerOpen] = useState(true);
  const [victimData, setVictimData] = useState({});
  const [victimImage, setVictimImage] = useState([]);
  const [hidden, setHidden] = useState(true);
  const [imageId, setImageId] = useState(0);

  const toggleContainer = () => {
    if (isContainerOpen) return setIsContainerOpen(false);
    setIsContainerOpen(true);
  };

  const getVictimData = async (id) => {
    try {
      const response = await apiRequest({
        method: "get",
        url: `spisak-srebrenica/${id}`,
      });
      if (response.status === 200) {
        setVictimData(response.data);
        setIsLoading(false);
      }
    } catch (err) {
      console.log(err);
    }
  };
  const getVictimImage = async (id) => {
    try {
      const response = await apiRequest({
        method: "get",
        url: `spisak-srebrenica/pictures/${id}`,
      });
      if (response.status === 200) {
        setVictimImage(response.data);
      }
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    const parsed = queryString.parse(window.location.search);
    const { lat, long, id } = parsed;
    setLat(lat);
    setLong(long);
    getVictimData(id);
    getVictimImage(id);
  }, []);

  return isLoading ? (
    <div className="loading-container">
      <ThreeDots />
      <h3>Učitavanje mape</h3>
    </div>
  ) : (
    <div className="container-map">
      <MapHeader />
      {hidden ? null : (
        <Galery>
          <div className="head">
            <span>Galerija slika žrtve</span>
            <AiOutlineClose
              className="icon"
              size={30}
              onClick={() => setHidden(true)}
            />
          </div>
          <div className="image-cont">
            {victimImage.length > 0 ? (
              <Zoom>
                <img
                  src={victimImage[imageId].url}
                  alt="slika"
                  className="victimImg"
                  style={{ width: 100, minWidth: 330 }}
                />
              </Zoom>
            ) : (
              <span className="message">
                Trenutno nema dostupnih fotografija
              </span>
            )}
          </div>

          {victimImage.length > 0 ? (
            <div className="nav">
              <MdArrowBackIos
                className="arrow"
                size={30}
                onClick={() =>
                  imageId === 0 ? setImageId(2) : setImageId(imageId - 1)
                }
              />
              <span>
                {imageId + 1}/{victimImage.length}
              </span>
              <MdArrowForwardIos
                className="arrow"
                size={30}
                onClick={() =>
                  imageId === victimImage.length - 1
                    ? setImageId(0)
                    : setImageId(imageId + 1)
                }
              />
            </div>
          ) : null}
        </Galery>
      )}
      <Map
        lat={lat}
        long={long}
        onClick={() => toggleContainer()}
        victimData={victimData}
      />
      <InfoContainer
        isOpen={isContainerOpen}
        victimData={victimData}
        setHidden={setHidden}
        hidden={hidden}
      />
    </div>
  );
};

export default MapPage;

const Galery = styled.div`
  position: absolute;
  width: 1315px;
  height: 80vh;
  left: 0px;
  right: 0px;
  top: 0px;
  margin: 0 auto;
  background: #ffffff;
  border-radius: 20px;
  z-index: 999;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0 1px 10px black;

  .victimImg:hover {
    width: 500px !important;
    cursor: -moz-zoom-in;
    cursor: -webkit-zoom-in;
    cursor: zoom-in;
  }

  .head {
    width: 100%;
    flex: 1;
    padding: 3rem;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    font-family: Calibri;
    font-style: normal;
    font-weight: normal;
    font-size: 36px;
    line-height: 44px;
    color: rgba(0, 0, 0, 0.5);

    span {
      font-size: 25px;
      font-family: Barlow !important;
    }
    .icon {
      cursor: pointer;
      color: rgba(0, 0, 0, 0.5);
    }
  }
  .image-cont {
    flex: 5;
    width: 100%;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    img {
      width: 90%;
      border-radius: 20px;
      height: 90%;
      overflow: hidden;
      box-shadow: 0 2px 10px black;
      @media (max-width: 575px) {
        width: 90%;
        border-radius: 20px;
      }
    }
    .message {
      font-size: 30px;
      color: rgba(0, 0, 0, 0.5);
    }
  }
  .nav {
    flex: 1;
    width: 100%;
    margin-top: 1rem;
    display: flex;
    align-items: center;
    justify-content: space-around;
    span {
      font-size: 25px;
    }
    .arrow {
      cursor: pointer;
      color: rgba(0, 0, 0, 0.5);
    }
  }
  @media (max-width: 575px) {
    width: 90%;
    top: 0;
    bottom: 0;
    margin: auto;
    left: 0;
    border: 1px solid rgba(0, 0, 0, 0.5);
    .image-cont .message {
      font-size: 20px;
      text-align: center;
    }
  }
  @media (min-width: 575px) and (max-width: 1440px) {
    width: 90%;
  }
`;
