import React from "react";

import mmsLogo from "assets/newLogo.png";
import arrowLeft from "assets/arrow-left.svg";
import infoImg from "assets/info.svg";

import { Row, Col } from "react-bootstrap";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";

const MapHeader = () => {
  const history = useHistory();
  return (
    <Row className="map-header-wrapper">
      <Col md={4} sm={4} xs={4}>
        <div className="back-to-search">
          <img
            onClick={() => history.goBack()}
            src={arrowLeft}
            alt="Strelica nazad"
            className="back-arrow"
          />
          <span onClick={() => history.goBack()} className="back-text">
            Nazad na rezultate pretrage
          </span>
        </div>
      </Col>
      <Col md={4} sm={4} xs={4}>
        <img
          onClick={() => history.push("/")}
          style={{ cursor: "pointer" }}
          alt="Memorial Center Srebrenica"
          src={mmsLogo}
          className="map-logo"
        />
      </Col>
      {/* <Col md={4} sm={4} xs={4}>
        <Link
          to={{
            pathname:
              "https://srebrenicamemorial.org/bs/stranica/o-nama/3?tab=historija-memorijalnog-centra",
          }}
          target="_blank"
          style={{ textDecoration: "none", color: "#fff" }}
        >
          <div className="info-img-wrapper">
            <img src={infoImg} alt="Info" className="info-image" />
            <p className="info-text">O centru</p>
          </div>
        </Link>
      </Col> */}
    </Row>
  );
};

export default MapHeader;
